import React from 'react'
import Img from 'gatsby-image/withIEPolyfill'
import cs from 'classnames'
import style from './SecondaryProjectItem.module.scss'
import Link from '../Link'

const SecondaryProjectItem = ({
  image,
  title,
  link,
  description,
  alignEnd = false,
}) => {
  const preparedDescription =
    description.length > 90 ? `${description.slice(0, 87)}...` : description

  return (
    <article className={style.root}>
      <Link
        to={link}
        className={cs('flex flex-wrap -m-2 sm:-m-4 text-gray-900', {
          'lg:items-end': alignEnd,
        })}
      >
        <div className="w-1/3 md:w-1/2 p-2 sm:p-4">
          {image ? (
            <Img
              className={style.image}
              fixed={image.localFile.childImageSharp.fixed}
              backgroundColor="#ebebec"
            />
          ) : null}
        </div>
        <div className="w-2/3 md:w-1/2 p-2 sm:p-4 max-w-md">
          <h3 className="mb-4">{title}</h3>
          <div
            className="primary-description"
            dangerouslySetInnerHTML={{ __html: preparedDescription }}
          />
        </div>
      </Link>
    </article>
  )
}

export default SecondaryProjectItem
