import React from 'react'
import HorizontalScrolling from '../HorizontalScrolling'
import SecondaryProjectItem from '../SecondaryProjectItem'
import style from './ProjectsPage.module.scss'

const ProjectsPage = ({
  data: {
    allWordpressWpProject: { nodes: projects },
  },
}) => (
  <HorizontalScrolling className="items-center">
    <div className={style.verticalTitle}>
      <h1>Готовые объекты</h1>
    </div>
    <div className={style.projectsContainer}>
      {projects.map((item, i) => (
        <div key={item.id} className={style.projectItem}>
          <SecondaryProjectItem
            alignEnd={i % 2 !== 0}
            title={item.title}
            link={item.link}
            image={item.acf.card_image ? item.acf.card_image : null}
            description={item.acf.short_description}
            isNewProject={item.acf.is_new_project}
            year={item.acf.year}
          />
        </div>
      ))}
    </div>
  </HorizontalScrolling>
)

export default ProjectsPage
