import { graphql } from 'gatsby'

import ProjectsPage from '../../components/ProjectsPage'

export default ProjectsPage

export const query = graphql`
  query {
    allWordpressWpProject(filter: { status: { eq: "publish" } }) {
      nodes {
        id
        title
        link
        acf {
          short_description
          card_image {
            localFile {
              childImageSharp {
                fixed(width: 350, height: 485, quality: 95) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`
